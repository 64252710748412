import React, {useState} from 'react';
import {Button, Checkbox, Form, Input, message, Modal, Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {FormInstance} from "antd/lib/form";
import {api} from "../../../service/apiClient";
import {finalize} from 'rxjs/operators';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};


type NewUserFormProps = {
    modal: CreateUserModal,
    email: string
}

const formRef = React.createRef<FormInstance>(); // Link form submit event to modal button
const NewUserForm = (props: NewUserFormProps) => {
    
    const [form] = Form.useForm();
    const [checked, setChecked] = useState(false)

    // On valid form submission
    const onFinish = (values: any) => {
        props.modal.setState({confirmLoading: true});
        // TODO - test with no server running
        api.createUser(values.email, values.password, values.name)
            .pipe(finalize(() => props.modal.setState({confirmLoading: false})))
            .subscribe((created: boolean) => {
                if (created) {
                    props.modal.setState({visible: false});
                    formRef.current!.resetFields();
                    message.success("User created")
                } else {
                    message.error("User/password denied")
                }
            }, error => {
                message.error(error)
            })
    };

    const toggleCheck = () => {
        setChecked((was) => { return !was })
    }

    return (
        <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
            ref={formRef}
        >
            <Form.Item
                name="email"
                label="E-mail"
                initialValue={props.email}
                rules={[
                    {
                        type: 'email',
                        message: 'Invalid E-mail',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password',
                    },
                ]}
                hasFeedback
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password',
                    },
                    ({getFieldValue}) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The two passwords that you entered do not match');
                        },
                    }),
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                name="name"
                rules={[{required: true, message: 'Please input your name', whitespace: true}]}
                label={
                    <span>Name&nbsp;
                        <Tooltip title="What do your friends call you? (i.e 'John Doe' or 'Worlds best Engineer')"><QuestionCircleOutlined/></Tooltip>
                    </span>
                }
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                    {validator: (_, value) => checked ? Promise.resolve() : Promise.reject('Accept terms of service')},
                ]}
                {...tailFormItemLayout}
            >
                &#8203;<Checkbox value={checked} checked={checked} onClick={() => toggleCheck()}>
                    I agree to the <a href='https://storage.googleapis.com/geokon-public-assets/Open%20API%20Terms%20of%20Service.pdf' target="_blank" rel="noreferrer">terms of service</a>
                </Checkbox>
            </Form.Item>
        </Form>
    );
}

interface NewUserEmail {
    email: string
}

class CreateUserModal extends React.Component<NewUserEmail> {

    state = {
        visible: false,
        confirmLoading: false
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        formRef.current!.submit();
    };

    handleCancel = () => {
        formRef.current!.resetFields();
        this.setState({
            visible: false,
            confirmLoading: false
        });
    };

    render() {
        const { visible, confirmLoading} = this.state;
        return (
            <Button size={"small"} type="link" style={{paddingLeft:0, marginTop:0, height:20}} onClickCapture={this.showModal}>
                Sign up
                <Modal
                    title="Create User"
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleOk}>
                            Submit
                        </Button>,
                    ]}
                >
                    <NewUserForm modal={this} email={this.props.email}/>
                </Modal>
            </Button>
        );
    }
}

export default CreateUserModal;