import ResponseHandler, {api} from "./apiClient";
import {deleteCookie, getCookie, setCookie} from "./util/cookies";
import {finalize, map, mergeMap} from "rxjs/operators";
import {Session, UserSettings} from "./domain/user";
import {EMPTY, Observable, of, Subscription} from "rxjs";
import assert from "assert";
import {AuthenticationResult} from "../components/pages/user/authenticationResult";
import {message} from "antd";

class SessionManager {

    private static COOKIE_NAME = "sessionAuthToken";
    private static DEFAULT_COOKIE_DURATION_MS = 30 * 24 * 60 * 60 * 1000
    private session?: Session;
    private accountIdListeners = [] as ((accountId: string) => void)[]

    /**
     * Creates a new session.
     *
     * @param email
     * @param password
     * @param callback true if authenticated
     */
    public authenticateNewSession(email: string, password: string, callback: (started: AuthenticationResult) => void) {
        assert(this.session === undefined, "Authenticate should only be called once on session start")
        const parent = this
        api.authenticateUser(email, password, new class implements ResponseHandler<string> {
            onResult(sessionAuthToken: string): void {
                api.getUserSession(sessionAuthToken).subscribe({next: (session) => {
                    parent.session = session
                    callback(AuthenticationResult.SUCCESS)
                    setCookie(SessionManager.COOKIE_NAME, sessionAuthToken, parent.getUserSettings().cookieSessionDurationMillis ?? SessionManager.DEFAULT_COOKIE_DURATION_MS)
                    }, error: e => {message.error(e).then()}})
            }
            // depending on the error, set a different invalid, not just invalid password since that could
            // confuse people if it's the API not responding
            onError(errorCode: number, message: string, data: any): void {
                if (errorCode === -32005) // denied response code
                    callback(AuthenticationResult.INVALID_CREDENTIALS)
                else if (errorCode === -32008)
                    callback(AuthenticationResult.RESET_PASSWORD_REQUIRED)
                else
                    callback(AuthenticationResult.TIMEOUT)
            }
        }())
    }

    /**
     * Creates a new session from SSO service
     *
     * @param token given to us from SSO
     * @param service which sso service are we using: [google, microsoft]
     * @param callback true if authenticated
     */
    public authenticateNewSessionSSO(token: string, service: string, callback: (started: AuthenticationResult) => void) {
        assert(this.session === undefined, "Authenticate should only be called once on session start")
        const parent = this

        let handler = new class implements ResponseHandler<string> {
            onResult(sessionAuthToken: string): void {
                api.getUserSession(sessionAuthToken).subscribe({
                    next: (session) => {
                        parent.session = session
                        callback(AuthenticationResult.SUCCESS)
                        setCookie(SessionManager.COOKIE_NAME, sessionAuthToken, parent.getUserSettings().cookieSessionDurationMillis ?? SessionManager.DEFAULT_COOKIE_DURATION_MS)
                    }, error: e => {
                        message.error(e).then()
                    }
                })
            }

            // depending on the error, set a different invalid, not just invalid password since that could
            // confuse people if it's the API not responding
            onError(errorCode: number, errorMessage: string, data: any): void {
                message.error(errorMessage, 5)
                callback(AuthenticationResult.TIMEOUT)
            }
        }

        if (service === "google") {
            api.loginGoogleSSO(token, handler)
        }
        if (service === "microsoft") {
            api.loginMicrosoftSSO(token, handler)
        }
    }

    /**
     * Check if there is a cookie with existing session authentication info to bypass password login.
     * Starts session if there is one.
     *
     * @param callback true if authenticated
     */
    public authenticatExistingSession(callback: (started: AuthenticationResult) => void) {
        assert(this.session === undefined, "Authenticate should only be called once on session start")
        const parent = this
        let possibleSessionToken = getCookie(SessionManager.COOKIE_NAME)
        if (possibleSessionToken) {
            api.getUserSession(possibleSessionToken)
                .subscribe((session) => {
                    parent.session = session
                    callback(AuthenticationResult.SUCCESS)
                }, err => {
                    this.endSession()
                })
        } else {
            callback(AuthenticationResult.PENDING)
        }
    }

    public getSessionAuthToken(): string {
        return this.session?.sessionAuthToken as string
    }

    public endSession() {
        deleteCookie(SessionManager.COOKIE_NAME)
        window.location.reload()
    }

    public isContinuedSession(): boolean {
        return getCookie(SessionManager.COOKIE_NAME) === (this.session?.sessionAuthToken)
    }

    /**
     *
     * @param accountId
     * @param forceUpdate used to indicate if we should update even if the accountId didn't change. Used for cases where we want to fire off listeners
     */
    public updateSessionAccount(accountId: string, forceUpdate: boolean = false): Observable<string> {
        assert(this.session !== undefined, "Must authenticate first")
        if (this.session?.accountId !== accountId || forceUpdate) {
            this.session!.accountId = accountId
            return api.updateUserSession(this.getSessionAuthToken(),{accountId})
                .pipe(mergeMap(() => {
                    return of(accountId)
                }), finalize(()=> { // Fire any listeners
                    for (let listener of this.accountIdListeners) {
                        listener(accountId)
                    }
                }))
        } else {
            return of(accountId)
        }
    }

    public clearSessionAccount(): Subscription {
        return api.updateUserSession(this.getSessionAuthToken(), {accountId: null})
            .subscribe({
                next: (newSession) => {
                    this.session = newSession;
                },
                error: (error) => {
                    message.error('Error clearing session account:', error);
                }
            });
    }

    /**
     * Returns the current account to display for this session, if one exists.
     * If one does not exist it will choose an account at random.
     * If no accounts exist it will return empty.
     */
    public getSessionAccountId(): Observable<string> {
        assert(this.session !== undefined, "Must authenticate first")
        if (this.session.accountId) {
            return of(this.session.accountId)
        } else { // None exists for the session so attempt to set one
            return api.getUserAccounts(this.session.sessionAuthToken)
                .pipe(mergeMap((accounts) => {
                    if (accounts.length > 0) {
                        return this.updateSessionAccount(accounts[0].id)
                    } else {
                        return EMPTY
                    }
                }))
        }
    }

    public isAdminUser(): boolean {
        assert(this.session !== undefined, "Must authenticate first")
        return this.session.user.adminUser;
    }

    public isAdminSession(): boolean {
        assert(this.session !== undefined, "Must authenticate first")
        return this.session.user.adminUser && this.session.user?.settings?.enableAdmin;
    }

    public isDarkSession(): boolean {
        assert(this.session !== undefined, "Must authenticate first")
        return this.session.user?.settings?.darkMode;
    }

    public subscribeAccountIdChange(listener: (accountId: string) => void) {
        this.accountIdListeners.push(listener);
    }

    public unsubscribeAccountIdChange(listener: any) {
        const index = this.accountIdListeners.indexOf(listener, 0);
        if (index > -1) {
            this.accountIdListeners.splice(index, 1);
        }
    }

    public getUserSettings(): UserSettings {
        return Object.assign({}, this.session?.user.settings)
    }
    
    public updateUserSettings(settings: UserSettings): Observable<UserSettings> {
        return api.updateUser(this.getSessionAuthToken(), null, null, settings)
            .pipe(map((user) => {
                if (settings.cookieSessionDurationMillis) {
                    setCookie(SessionManager.COOKIE_NAME, this.session!.sessionAuthToken, settings.cookieSessionDurationMillis)
                }
                // @ts-ignore
                this.session.user.settings = user.settings
                // @ts-ignore
                return Object.assign({}, this.session.user.settings)
            }))
    }

    private static singletonInstance: SessionManager;

    public static get Instance() {
        return this.singletonInstance || (this.singletonInstance = new this());
    }


}

export const session = SessionManager.Instance;