import {WifiConfig} from "./config";
import {Ism} from "./ism";

export class Logger {
    constructor(serialId: string, properties: any, config: any, network: Logger[]|undefined=undefined) {
        this.serialId = serialId
        this.properties = properties;
        this.configuration = config;
        this.network = network
        this.key = serialId
    }

    serialId: string;
    properties: {
        active: boolean; // Deprecated
        dataType: string;
        dataTypeDescription: string;
        lastComm: Date;
        nodeType: string;
        nodeTypeDescription: string;
        gateway: string;
        lastObservation: Date;
        lastUpdate: Date;
        model: string;
        channels: number;
        firmware: {
            latest: number;
            lts: number;
            version: number
        };
        networkModule: {
            id: string;
            firmware: {
                latest: string;
                lts: string;
                version: string
            };
            model: string;
        } | undefined;
        locationGeo: {
            lat: number;
            lng: number;
            accuracyMeters: number;
        } | undefined;
        wifi: {
            enabled: boolean;
            macAddress: string;
            lastIp: string;
            imei: string
        } | undefined;
        cell: {
            enabled: boolean;
            active: boolean;
            activatedDate: Date;
            imei: string
        } | undefined;
        satellite: {
            enabled: boolean;
            active: boolean;
            activatedDate: Date;
            imei: string
        } | undefined;
        ism: {
            enabled: boolean;
            lorawan: {
                deui: string;
            }
        }
    };
    configuration: {
        info: {
            name: string;
            notes: string;
        };
        geonet: {
            deploymentPeriod: number;
            utcOffset: number;
            deploymentMode: number;
        };
        logging: {
            powerMode: string;
            powerModeDescription: string;
            readInterval: number;
            timeZone: string;
        };
        wifi: WifiConfig | undefined;
        cell: {
            state: string;
        } | undefined;
        satellite: {
            state: string;
        } | undefined;
        ism: Ism | undefined
        location: {
            syncEnabled: boolean;
        } | undefined;
    };
    network: Logger[] | undefined;

    // Fields for front end
    key: string; // So that Logger can be used directly in tables

    /**
     * Determine if logger has cellular capabilities.
     */
    hasCell(): boolean {
        return this?.properties?.networkModule?.model === "C-IAB-3G"
            || this?.properties?.networkModule?.model === "C-IAB-LTEM"
            || this?.properties?.networkModule?.model === "C-IAB-LTEM-ISM"
            || this?.properties?.networkModule?.model === "C-IAB-3G-ISM"
            || this?.properties?.networkModule?.model === "C-IAB-LTE1"
            || this?.properties?.networkModule?.model === "C-IAB-LTE1-ISM"
    }

    hasWifi(): boolean {
        return this?.properties?.networkModule !== undefined && this.properties.wifi != null
    }

    hasSat(): boolean {
        return this?.properties?.networkModule?.model === "C-IAB-SAT"
    }

    hasIsm(): boolean {
        return this?.properties?.networkModule?.model === "C-IAB-ISM"
            || this?.properties?.networkModule?.model === "C-IAB-LTEM-ISM"
            || this?.properties?.networkModule?.model === "C-IAB-3G-ISM"
            || this?.properties?.networkModule?.model === "C-IAB-LTE1-ISM"
    }

    /**
     * Determine if logger is enabled to actually talk to the server (cell requires active data plan, wifi doesn't)
     */
    isNetworkActive(): boolean {
        return this.isCellActive() || this.isWifiActive() || this.isSatActive() || this.isLorawanActive();
    }

    isCellActive(): boolean {
        return this.hasCell() && this.configuration.cell?.state === "ACTIVE";
    }

    isWifiActive(): boolean {
        return this.hasWifi()
    }

    isLorawanActive(): boolean {
        return this.properties.nodeType === "standalone" && this.hasIsm() && !this.hasCell()
    }

    isSatActive(): boolean {
        return this.hasSat() && this.configuration.satellite?.state === "ACTIVE";
    }

    /**
     * Put serial in () if doesn't include serial, if no name use serial - e.g 'Cell Logger 287 (2143287)'
     */
    genDisplayName(): string {
        return this.configuration && this.configuration.info.name ?
            this.configuration.info.name.includes(this.serialId) ? this.configuration.info.name : this.configuration.info.name + " (" + this.serialId + ")"
            : this.serialId
    }

    isGateway(): boolean {
        return this.properties?.nodeType === 'supervisor' ||  this.properties?.nodeType === 'gateway'
    }

    isStarEndpoint(): boolean {
        return this.properties?.nodeType === 'endpoint'
    }

    /**
     * Active LoRaWAN endpoint OR star endpoint (not gateway)
     */
    isActiveIsmEndpoint(): boolean {
        return this.hasIsm() && !this.isGateway() && this.properties.ism.enabled;
    }

    isGen1Architecture(): boolean {
        return this.properties.firmware ? this.properties.firmware.version < 1000 : false;
    }
}
