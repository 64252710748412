import {Buffer} from "buffer";
import {WifiConfig} from "./config";
import {Ism} from "./ism";


export enum ActivationState {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    STANDBY = "STANDBY"
}

export class SyncSetting {

    constructor(value: string, name: string) {
        this.value = value
        this.name = name
    }

    name: string;
    value: string

    getHex(): string {
        return Buffer.from(this.value, 'base64').toString('hex')
    }

    getAscii(): string {
        return Buffer.from(this.value, "base64").toString("utf8")
    }

}

export interface SyncSettings {
    [key: string]: SyncSetting;
}

export class Module {

    constructor(address: string, properties: any, config: any, syncSettings: SyncSettings, fwVersion: any) {
        this.address = address;
        this.properties = properties;
        this.config = config;
        this.syncSettings = syncSettings;
        this.fwVersionProps = fwVersion;
    }

    address: string;
    syncSettings: SyncSettings;
    fwVersionProps: {
        latest: string;
        lts: string;
        version: string
    };
    config: {
        cellConfig: {
            moduleCellActivationState: ActivationState;
        };
        wifiConfig: WifiConfig;
        satConfig: {
            moduleSatActivationState: ActivationState;
        };
        ism: Ism;
        network: {
            maxMinutes: number;
        }
        moduleInfo: {
            name: string;
            notes: string;
        }
        tippingBucketMinute: number;
        tippingBucketObs: number;
        location: {
            syncEnabled: boolean;
        };
    };
    properties: {
        hardwareProperties: {
            hardwareModel: string;
            hardwareVersion: string;
            wanTypes: string[];
        };
        satProperties:{
            enabled: boolean;
            active: boolean;
            activatedDate: Date;
            imei: string
        };
        cellProperties:{
            enabled: boolean;
            active: boolean;
            activatedDate: Date;
            imei: string
        };
        wifiProperties:{
            enabled: boolean;
            "lastIp": string;
            macAddr: string;
            imei: string
        };
        ism: {
            enabled: boolean;
            lorawan: {
                deui: string;
            }
        }

    };
    props: any;

    /**
     * Determine if the module has connections enabled
     */
    hasCell(): boolean {
        return this.properties.hardwareProperties ?
                this.properties.hardwareProperties.hardwareModel === "C-IAB-3G"
                || this.properties.hardwareProperties.hardwareModel === "C-IAB-LTEM"
                || this.properties.hardwareProperties.hardwareModel === "C-IAB-LTEM-ISM"
                || this.properties.hardwareProperties.hardwareModel === "C-IAB-3G-ISM"
                || this.properties.hardwareProperties.hardwareModel === "C-IAB-LTE1"
                || this.properties.hardwareProperties.hardwareModel === "C-IAB-LTE1-ISM"
            : false
    }

    hasWifi(): boolean {
        return this.properties.hardwareProperties ? this.properties.hardwareProperties.hardwareModel === "C-IAB-WIFI" : false
    }

    hasSat(): boolean {
        return this.properties.hardwareProperties ? this.properties.hardwareProperties.hardwareModel === "C-IAB-SAT" : false
    }

    hasIsm(): boolean {
        return this.properties.hardwareProperties ?
            this.properties.hardwareProperties.hardwareModel === "C-IAB-ISM"
            || this.properties.hardwareProperties.hardwareModel === "C-IAB-LTEM-ISM"
            || this.properties.hardwareProperties.hardwareModel === "C-IAB-3G-ISM"
            || this.properties.hardwareProperties.hardwareModel === "C-IAB-LTE1-ISM"
            : false

    }


    /**
     * Determine if the module is active and talking to the server
     */
    isNetworkActive(): boolean {
        return this.isCellActive() || this.isWifiActive() || this.isSatActive() || this.isLorawanActive();
    }

    isCellActive(): boolean {
        return this.hasCell() && this.config.cellConfig?.moduleCellActivationState === ActivationState.ACTIVE;
    }

    isWifiActive(): boolean {
        return this.hasWifi()
    }

    isSatActive(): boolean {
        return this.hasSat() && this.config.satConfig?.moduleSatActivationState === ActivationState.ACTIVE;
    }

    isLorawanActive(): boolean {
        return this.hasIsm()
    }
}
