import React, {useEffect, useRef, useState} from "react";
import {Button, message, Select} from 'antd';
import {api} from "../../../service/apiClient";
import {session} from "../../../service/session";
import {Account} from "../../../service/domain/account";
import {useHistory} from "react-router";

const { Option } = Select;

const AccountSelect = () => {
    const history = useHistory();
    const token = session.getSessionAuthToken()
    const [data, setData] = useState<Account[]>([]);
    const [selectedValue, setSelectedValue] = useState();
    const [loading, setLoading] = useState(false);

    // Use useRef to hold the previous value of data. This is used to know whether we should clear accountId associated with session
    const prevData = useRef<Account[]>([]);

    function onChange(accountId: string) {
        setLoading(true)
        session.updateSessionAccount(accountId).subscribe()
    }

    const updateSelected = useRef(() => {
        setLoading(true)
        api.getUserAccounts(token)
            .subscribe({
                next: (accounts) => {
                    if (accounts.length > 0) {
                        setData(accounts)
                        session.getSessionAccountId()
                            .subscribe({ next: (accountId) => {
                                // @ts-ignore
                                setSelectedValue(accountId)
                                setLoading(false)
                            }, error:(error) => {message.error(error)}})
                    } else {
                        setSelectedValue(undefined)
                        setLoading(false)
                        setData([])
                    }
                },
                error:(error) => {message.error(error)}})
    })

    // Load currently selected account for session, if none chosen then pick a default
    useEffect(() => {
        updateSelected.current()
        
        // Any time session account changes must update the selected value (stateless)
        const listener = (accountId: string) => updateSelected.current()
        session.subscribeAccountIdChange(listener)
        return () => session.unsubscribeAccountIdChange(listener)
    }, [updateSelected]);

    useEffect(() => {
        // Check if the previous data had elements and the current data is empty. If this is true, we should clear the account associated with the session
        if (prevData.current.length > 0 && data.length === 0) {
            session.clearSessionAccount()
        }

        prevData.current = data;

    }, [data]);

    // Load latest when user opens
    function onFocus(opened: boolean) {
        if (opened)
            updateSelected.current()
    }

    // names should only not be there on dev
    const options = data.map(account => <Option value={account.id} key={account.id}>{account.name !== undefined ? account.name : account.id}</Option>);
    return (
        <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select an account"
            optionFilterProp="children"
            value={selectedValue}
            onChange={onChange}
            loading={loading}
            onDropdownVisibleChange={onFocus}
            filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent = {
                <div>
                    <Button type="link" size={'small'} onClick={() => history.push("/settings/accounts")}>Add account</Button>
                </div>
            }
        >
            {options}
        </Select>
    )

}

export default AccountSelect