import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import LoginPage from "./components/pages/user/login";
import {session} from "./service/session";
import ApplicationRoutes from "./config/AppRoutes";
import {AuthenticationResult} from './components/pages/user/authenticationResult';
import {ConfigProvider, theme} from 'antd';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";

const GEOKON_BLUE = 'rgba(56,128,196,1)'
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
const microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID as string;

const msalConfig = {
    auth: {
        clientId: microsoftClientId,
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin,
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default GEOKON_BLUE

export function launchApp() {
    ReactDOM.render(
        <React.StrictMode>
            <ConfigProvider theme={{
                algorithm: session.getUserSettings().darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                token: session.isAdminSession() ? {
                    colorPrimary: GEOKON_BLUE,
                } : {colorPrimary: '#1890ff'} // Old ant design primary, new is #1677ff
            }}>
                <ApplicationRoutes />
            </ConfigProvider>
        </React.StrictMode>
        ,
        document.getElementById('root')
    );
}

session.authenticatExistingSession(started => {
    if (started === AuthenticationResult.SUCCESS) {
        launchApp()
    } else {
        // Launch login page
        ReactDOM.render(
            <MsalProvider instance={msalInstance}>
                 <GoogleOAuthProvider clientId={googleClientId}>
                    <React.StrictMode>
                        <LoginPage />
                    </React.StrictMode>
                </GoogleOAuthProvider>
            </MsalProvider>
            ,
            document.getElementById('root')
        );
    }
})



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
