import React from "react";
import {session} from "../../../../service/session";
import {message, notification} from "antd";
import {useHistory} from "react-router";

/**
 * Since admins have a lot of power to break things, make sure the user is alerted/reminded they're running as admin.
 */
const AdminSessionNotification = () => {
    const history = useHistory();

    // Only alert on new logins not continued sessions
    if (session.isAdminSession() && !session.isContinuedSession())
        notification.open({
            key: 'adminSession',
            message: 'Admin Session!',
            description: 'You are running as an admin. Please be careful not to remove or modify customer data! Click to disable admin.',
            onClick: () => {
                history.push('/settings/user')
                var settings = session.getUserSettings()
                settings.enableAdmin = false
                session.updateUserSettings(settings).subscribe(() => {
                    message.success("Admin session disabled").then()
                    notification.destroy('adminSession')
                    setTimeout(() => window.location.reload(), 1500);
                })
            },
            type: 'warning',
            duration: 15,
            placement: 'top',
            style: {cursor: "pointer"}
        });

    return (<></>);
}

export default AdminSessionNotification;